import {
  CheckoutData,
  LeadList,
  ModuleName,
  OutreachCampaignScreen,
  OutreachCampaignTemplate,
  Screen,
  VideoScreen,
} from "../types"
import { UsageDetails } from "../types/Usage"

export const isLocalDev = process.env.NODE_ENV === "development"

export const isDev = isLocalDev || window.location.href.includes("dev")

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const calculatePrice = (checkoutData: CheckoutData): number => {
  const normalAmount =
    Math.round(checkoutData.wordsCount * 0.01 * checkoutData.quantity * 100) /
    100
  return normalAmount < 0.5 ? 0.5 : normalAmount
}

export const getAvailableCredits = (usageDetails: UsageDetails): number => {
  const usedCredits = usageDetails.current_usage / 1000
  const totalCredits = usageDetails.usage_limit / 1000
  const remainingCredits = totalCredits - usedCredits
  return remainingCredits
}

export const getCampaignCharacterCount = (
  leadList: LeadList,
  campaignTemplate: OutreachCampaignTemplate,
): [number, string] => {
  const { text: template, variables } = campaignTemplate
  const { data } = leadList
  const result = data.map((e) => {
    let result = template
    variables.forEach((variable) => {
      result = result.replace(`{${variable}}`, e[variable])
    })
    return result
  })
  const count = result.join("").length
  const preview = result[0]
  return [count, preview]
}

type GetNavigationPaths = (moduleName: ModuleName) => {
  back: string
  next: string
  initial: string
}
export const getNavigationPaths: GetNavigationPaths = (moduleName) => {
  switch (moduleName) {
    case ModuleName.videos:
      return {
        back: `/`,
        next: `/${Screen.videos}/${VideoScreen.createVideoMessage}`,
        initial: "/",
      }
    case ModuleName.outreachCampaigns:
      return {
        back: `/${Screen.outreachCampaigns}`,
        next: `/${Screen.outreachCampaigns}/${OutreachCampaignScreen.createCampaignMessage}`,
        initial: `/${Screen.outreachCampaigns}`,
      }
  }
}
